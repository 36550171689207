.nested {
  margin-left: -5px;
}
.command {
  font-size: 60%;
  text-decoration: underline;
}
.column.main{
  overflow-x:auto;
}
.txt{
  color:black;
  font-weight:normal
}