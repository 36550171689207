fieldset {
  border: 0;
  margin: 0 auto;
  padding: 0;
  letter-spacing: -0.31em;
  display: inline-block;
}
.form-login {
}
.field.field-name-firstname.required {
  margin-right: 12.5px;
}
.field.field-name-lastname.required {
  margin-left: 12.5px;
}

#social-form-create > button {
  display: inline-block;
}

.login_container {
  display: inline-block;
}
.loginloader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  margin: 0px auto;
  border-top: 5px solid #d90916;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 1.4s linear infinite; /* Safari */
  animation: spin 1.4s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
