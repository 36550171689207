.order_element {
  display: block;
  height: auto;
  padding: 10px;
}
.order_element .desc_container {
  display: table;
}
.order_element .meta_container.btn {
  display: absolute;
}
.order_element .code {
  font-weight: 600;
  color: black;
  font-style: oblique;
  margin-bottom: 10px;
}
.order_element span {
  text-decoration: underline;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  padding: 5px;
}
.order_element .meta_name {
  display: table-cell;
  width: auto;
  padding: 5px;
}
.order_element .meta_name.btn > button {
  color: black;
  background: transparent;
  padding: 10px;
  border: 1.5px solid black;
  transition: all 0.3s ease-in-out;
}
.order_element .meta_name.btn > button:hover {
  background: black;
  border: 1.5px solid black;
  color: white;
  transition: all 0.3s ease-in-out;
}
.order_element .meta_name.btn:first-child {
  padding: 0px 10px;
}
.order_element .meta_name.btn {
  display: table-cell;
  padding: 0px;
  width: initial !important;
  margin: 0px;
  flex-direction: row;
  width: 50px;
}
.order_element .meta_name h1 {
  font-size: 17px;
  margin: 0px 10px;
  line-height: 25px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0;
}
.order_element .meta_name h3 {
  margin: 0px 10px;
  padding: 0;
  line-height: 10px;
  font-size: 17px;
}
.meta_container {
  display: table-row;
}
.desc_container p.subtitle {
  text-transform: capitalize;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.116);
  margin-top: 0px !important;
}
.order_element .foto_container {
  margin-right: 0px;
  float: left;
  max-width: 12%;
  margin-top: 12px;
  width: auto;
  text-align: center;
}
.order_element .foto_container p.subtitle {
  line-height: 15px;
  font-size: 12px;
  font-weight: 600;
  margin-top: 0px !important;
  margin: 0;
}
.order_element .foto_container img {
  height: 70px;

  margin-top: 5px;
}
.order_element .status {
  font-weight: 600 !important;
  text-transform: none !important;
  margin-top: 3px !important;
  margin-bottom: 3px;
}
.order_element .subtitle_sequel {
  color: black !important;
  font-weight: 600 !important;
  text-transform: none !important;
  margin-top: 3px !important;
  margin-bottom: 3px;
}
