.textbox-container {
  text-align: left;
  background: rgba(128, 128, 128, 0.075);
  margin-top: 40px;
  padding: 5px;
}
.textbox-container img {
  float: left;
  width: 30px;
  height: 30px;
  margin: 0 30px;
}
@media (max-width:1000px)
{
  .textbox-container img {
    float: left;
    width: 30px;
    height: 30px;
    margin: -5px 10px 15px 5px;
  }
}
.textbox-container h4,
.textbox-container h5 {
  color: black;
  padding: 0px 10px 0px 10px;
}
#my-orders-table td.col:first-child {
  width: 40%;
}
.max_height {
  max-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.col.name {
  font-weight: 700;
}
.textbox-container textarea {
  width: 80%;
  display: block;
  padding: 15px;
  font-size: 15px;
  font-weight: 400 !important;
}
@media (max-width:1000px)
{
  .textbox-container textarea {
    width:100%;
    display: block;
    padding: 15px;
    font-size: 15px;
    font-weight: 400 !important;
  }
 
}
.data_container .content {
  margin-left: 60px;
}

.data_container p {
  text-transform: none !important;
  font-weight: normal !important;
}
.textbox-container button {
  margin-left: auto;
  display: block;
  margin-top:5px;
}
.reply .subtitle {
  font-size: 11px;
  margin: 0px;
  padding: 0;
}
.reply {
  margin: 24px 0;
}
.reply h4 {
  margin: 5px;
}
.reply img {
  float: left;
}
.reply .data_container {
  display: block;
  margin-left: 30px;
}
.data.table.table-order-items tfoot tr td {
  text-align: right;
}
.col.discount {
  text-align: center;
}
.col.qty {
  text-align: center;
}
.true{
  opacity:1;
  visibility:visible;
}
.false{
  opacity:0;
  transition:all 1s ease-in-out;
  visibility:hidden;
}
#my-orders-table td.col.name p{
  height:30px;
  min-width:150px;
  color:black;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: none;
  font-weight: normal;
}
@media (max-width:990px)
{
  #my-orders-table td.col.name p{
    width:100%;
    height:auto;
    color:black;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: none;
    font-weight: normal;
  }
}
#my-orders-table td.col.name{
  width:30%;
  text-overflow: ellipsis;
  overflow-y:hidden;
  overflow-x: hidden;

}