.prev_card {
  height: auto;
  border-radius: 3px;
  padding: 25px;
  text-align: left;
  background: white;
  margin: 25px auto;
  transition: all 0.3s ease-in-out;
}
@media (max-width:1000px)
{
  .prev_card {
  height: auto;
  border-radius: 3px;
  padding: 25px;
  text-align: left;
  background: white;
  margin: 25px auto;
  transition: all 0.3s ease-in-out;
}


}
.middleOne .prev_card .cell_div {
  width: 50%;
}
.prev_card:hover {
  box-shadow: -1px 5px 25px -5px rgba(0, 0, 0, 0.8);
  transform: translate3d(0, -2px, 0);
  transition: all 0.3s ease-in-out;
}
#choser{
  box-shadow: -1px 5px 25px -5px rgba(0, 0, 0, 0.8);
  transform: translate3d(0, -2px, 0);
  transition: all 0.3s ease-in-out;
}
#choser:hover{
  box-shadow: none;
  transform: translate3d(0, 2px, 0);
  transition: all 0.3s ease-in-out;
}
.loader_innerpreventivatore {
  background: rgba(0, 0, 0, 0.144);
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}
.obscure {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.253);
}
.fullwidth {
  width: 100%;
}
.img_loader {
  padding: 0px !important;
  margin-top: 25px !important;
}
.img_loader .cell_div {
  padding: 1px;
}
.searchable,
.searchable:hover {
  transform: none;
}
.page_title.absolute {
  display: initial;
}
.title .middle_phaser {
  position: relative;
  display: inline-block;
}
.title .middle_phaser button {
  background: transparent;
  color: gray;
  border: none;
  padding: 5px;
}

.middleOne .cell_div:first-child {
  padding: 0px 5px 0px 0px !important;
}
.middleOne .cell_div:nth-child(2) {
  padding: 0px 0px 0px 5px !important;
}
.table_div.leftalign .prev_card {
  margin-top: 0px;
}
.page_title.absolute::after {
  content: "";
  height: 7px;
  margin: 15px 0;
  display: block;
  position: absolute;
  width: 70px;
  background: black;
}
.prev_card.noborder {
  -webkit-box-shadow: none;
  padding: 0px;
  margin: 5px auto;
  -moz-box-shadow: none;
  box-shadow: none;
}
.prev_card p {
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 700;
}
.table_div {
  display: table;
  margin-top: 25px;
  width: 100%;
}
.cell_div .prev_card {
  margin: 0px;
}
.row_div {
  display: table-row;
  width: 100%;
}
.cell_div h3 {
  text-align: left;
}
.cell_div {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.cell_div.address {
  width: 80%;
}
.cell_div.cap {
  width: auto;
  padding: 5px 25px;
}
.button_intestazione {
  display: inline-block;
  width: 150px;
  padding: 10px 15px;
  margin-right: -35px;
  background: rgb(6, 204, 6);
  border: 1px solid rgb(6, 204, 6);
}
.button_intestazione:hover {
  background: green;
  border: 1px solid green;
}
.button_container {
  width: 100%;
  text-align: right;
}
.title h1 {
  display: inline-block;
}
.title {
  text-align: justify;
  margin: 0px 0 70px 0;
}
.title ul {
  float: right;
  margin-top: 15px;
}
.title ul li {
  display: inline-block;
  margin: 0px 10px;
}
.title ul li:last-child{
  margin-right:0px;
}
.big_button {
  width: 150px;
  height: 150px;
  max-width: 10vw;
  max-height: 10vw;
  background: white;
  border-radius: 3px;
  /*   -webkit-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.28); */
  align-items: center;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  object-fit: contain;
  text-align: center;
  margin: 8px 15px;
  transition: all 0.3s;
}
.middleOne .prev_card.height350:first-child {
  margin-right: 12.5px;
}
.middleOne .prev_card.height350:nth-child(2) {
  margin-left: 12.5px;
}
.adder div.prev_card:nth-child(2) {
  margin-top: 25px;
}
.adder {
  margin-top:-55px;
  margin-left: 12px;
}
.big_button:hover {
  box-shadow: -1px 5px 25px -5px rgba(0, 0, 0, 0.8);
  transform: translate3d(0, -2px, 0);
  transition: all 0.3s;
}

.big_button a {
  margin-top: 25%;
  font-weight: 600;
  display: inline-block;
}
.big_button i {
  margin-top: 35%;
}
.img_uploader i.fa.fa-plus {
  position: absolute;
  top: 50%;
  margin-top:-90px;
  left: 50%;
  margin-left:-90px;
  padding:90px;
  display: block;
  color: rgb(38, 118, 238);
}
.img_uploader {
  position: relative;
  height: 100%;
  min-height: 250px;
  width: 100%;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

i {
  transition: all 0.3s ease-in-out;
}
i.rotate {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}
.invisible i {
  transition: all 0s ease;
}
.product_wrapper {
  width: 100%;
  height: 100%;
  text-align: left;
}
.animated.fadeIn.big_button:first-child {
  margin-left: 0px;
}
/* .big_button.translateleft {
  transform: translateX(-250px);
  transition: all 0.4s ease-in-out;
  border: 0.5px solid orangered;
} */
.inline_block {
  display: inline-block !important;
  transition-delay: all 0.4s;
}
.fadeIn.inline_block {
  transform: translateX(10px);
}
.opaque {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.float {
  position: absolute;
  transition: all 0.5s ease-in-out;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  z-index: 9999;
  width: 170px;
}
.float.brand1 {
  animation: translateLeft0;
  animation-fill-mode: forwards;
}
.float.brand2 {
  animation: translateLeft1;
  animation-fill-mode: forwards;
}
.float.brand3 {
  animation: translateLeft2;
  animation-fill-mode: forwards;
}
.float.brand4 {
  animation: translateLeft3;
  animation-fill-mode: forwards;
}
.fullframe {
  position: relative;
  overflow: auto;
  max-height: 600px;
}
.architonic-iframe {
  width: 100%;
  margin: 0;
  border: none;
  overflow: hidden;
}
@keyframes translateLeft0 {
  0% {
    transform: translate(150px, 0);
  }
  40% {
    transform: translate(150px, -38px);
  }
  100% {
    transform: translate(-15px, -38px);
  }
}
@keyframes translateLeft1 {
  0% {
    transform: translate(355px, 0);
  }
  40% {
    transform: translate(355px, -38px);
  }
  100% {
    transform: translate(-15px, -38px);
  }
}
@keyframes translateLeft2 {
  0% {
    transform: translate(550px, 0);
  }
  40% {
    transform: translate(550px, -38px);
  }
  100% {
    transform: translate(-15px, -38px);
  }
}
@keyframes translateLeft3 {
  0% {
    transform: translate(750px, 0);
  }
  40% {
    transform: translate(750px, -38px);
  }
  100% {
    transform: translate(-15px, -38px);
  }
}
.invisible {
  visibility: hidden;
  height: 0px !important;
  width: 0px !important;
  transition: all 0s ease;
}
.prev_card input,
.prev_card textarea {
  padding: 3px;
}
.input_container input {
  padding-left: 18px;
  padding-top: 4px;
}
.prev_card textarea {
  resize: vertical;
  height: 80px;
  max-height: 216px;
  margin:0px auto;
  font-size: 13px;
}
.button_abs {
  color: #d90916;
}
.droppable_wrapper {
  display: block;
  margin-top: 15px;
  border: 1px solid rgba(128, 128, 128, 0.39);
  padding: 20px;
}
.product_wrapper .prev_card.marginUp {
  margin-top: -125px;
  transition: all 0.6s ease-in-out;
  margin-left: 150px;
}
.product_wrapper .prev_card {
  border-radius: 3px;

  margin-left: 150px;
}
.product_wrapper .prev_card h3 {
  font-size: 25px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 2px;
  text-decoration: underline;
  font-weight: 800;
}
.MuiPaper-root.MuiAccordion-root.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  box-shadow: none;
  /*border: none;*/
  border: 0.5px solid black;
}
.MuiAccordionDetails-root .row_div:first-child .cell_div p.prezzo {
  text-align: center;
}
.MuiAccordionDetails-root .row_div:first-child .cell_div p.quantita {
  font-weight: 600;
  font-size: 14px;
  text-transform: none;
  color: rgba(0, 0, 0, 0.39);
  margin-left: 35px;
}
.MuiAccordionDetails-root .row_div:first-child .cell_div p.nome {
  font-weight: 700;
  white-space: nowrap;
  margin-bottom: 12px;
  width: 250px;
  font-size:13px;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.MuiAccordionDetails-root .row_div:first-child .cell_div p.brand {
  line-height: 10px;
  font-weight: normal;
  font-size: 12px;
  margin-top:10px;
  text-align: left;
  text-decoration: none !important;
}
.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  border-radius: 3px;
  /*border: 0.5px solid black;*/
  border-bottom: 0.5px solid black;
  /*-webkit-box-shadow: -1px 5px 25px -5px rgba(0, 0, 0, 0.8);*/
  /*-moz-box-shadow: -1px 5px 25px -5px rgba(0, 0, 0, 0.8);*/
  /*box-shadow: -1px 5px 25px -5px rgba(0, 0, 0, 0.8);*/
  background-color: white;
}
.fa.fa-cloud-upload {
  display: inline-block;
  cursor: pointer;
  font-size: 20px;
  margin-top: 15px;
  margin-left: 5px;
}
.fa.fa-cloud-upload:active{
  color:green;
}
.input_container{
  position: relative;
  text-align:left;
}
.input_container input{
  padding-left:5px;
}
.input_icon {
  width: 90% !important;
  display: inline-block;
}
.fa.fa-plus,
.fa.fa-minus {
  cursor: pointer;
  padding: 15px;
}
.prev_card input[type="file"] {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 1px;
  height: 1px;
}
span.iconafree {
  position: absolute;
  right: 25px;
  top: 10px;
  cursor: pointer;
}

.css-yk16xz-control,.select_modify__control {
  -moz-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 2px !important;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -moz-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: currentcolor none 0px !important;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  -moz-box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);
}
.css-1hwfws3 {
  -moz-box-align: center;
  align-items: center;
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
  padding: 2px 8px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  height: 46px;
}
.css-1g6gooi div input {
  box-sizing: content-box;
  width: 2px;
  background: rgba(0, 0, 0, 0) none repeat scroll 0px center;
  border: 0px none;
  font-size: inherit;
  opacity: 1;
  outline: currentcolor none 0px;
  padding: 0px;
  color: inherit;
  box-shadow: unset !important;
  position: absolute;
  top: 0px;
}
.css-b8ldur-Input input {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.MuiAccordionSummary-root.Mui-expanded,
.MuiAccordionSummary-root {
  height: 40px;

  min-height: 50px !important;
}
.css-b8ldur-Input > div > input {
  margin-top: -10px;
  margin-left: -5px;
}
.css-g1d714-ValueContainer,.select_modify__value-container {
  background: #fff;
  background-clip: padding-box;
  border: 2px solid #c6c6c6;
  border-radius: 3px;
  font-family: Gotham-Medium;
  font-size: 16px;
  height: 46px;

  line-height: 17px;
  padding: 0 14px;
  display: inline-block;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);
}
.MuiPaper-root.MuiAccordion-root.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded
  .MuiPaper-root.MuiAccordion-root.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  /*border: 0.5px solid rgba(0, 0, 0, 0.247);*/
  border: 0.5px solid black;
}
.cell_div button {
  padding: 15px;
  background: transparent;
}
.nomarginleft {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.height350 {
  height: 350px;
  transition: all 0.3s ease-in-out;
}
/* .cell_div p {
  line-height: 16px;
} */
.cont {
  width: 50%;
  margin-top: 10px;
}
.row_div.items {
  background: white;
  border-radius: 3px;
}

.fa.fa-trash:hover {
  color: rgb(201, 5, 5);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.btn_modifica {
  background: #17c42a !important;
  border: 2px solid ;
  color: white;
  font-weight: 400;
  font-size: 18px;
  width: 186px;
  line-height: 18px;
  box-sizing: border-box;
  border-radius: 0;
  transition: all 0.2s linear;
}
.btn_crea {
  background: rgb(38, 118, 238) !important;
  border: 2px solid ;
  color: white;
}
.btn_modifica:hover {
  background: #119120 !important;
  color: white;
  border: 2px solid #128f21;
}
img.fiximg{
    height: 30%;
    margin-top:35%;
    width: auto;
    padding: 1px;
}
img.fiximg.odoo {
  height: 60% !important;
  margin-top:20% !important;
  width: auto;
  padding: 1px;
}
.btn_annulla {
  background: #d90916 !important;
  border: 2px solid  !important;
  color: white;
}
.btn_annulla:hover {
  background: rgb(139, 3, 3) !important;
  border: 2px solid rgb(139, 3, 3) !important;
  color: white;
}

.btn_crea:hover {
  background: rgb(1, 1, 161);
  color: white;
  border: 2px solid rgb(1, 1, 161);
}
.droppable_wrapper .prev_card {
  width: 95%;
  margin-left: 0px !important;
  display: inline-block !important;
}
.MuiCollapse-wrapperInner {
  background: white;
}
.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  display: block;
  border: 0.5px solid black;
  background: transparent;
  /*background: silver;*/
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  font-weight: 500;
}
.selecter {
  width: 100%;
}
.MuiAccordionDetails-root {
  padding: 8px 16px 16px;
  margin: 5px 0;
  color: black;
  display: block !important;
  background: white;
  border-radius: 0px 0px 5px 5px !important;
}
.multi-select__multi-value__label {
  display: inline-block;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.171);
  max-width: 100%;
}

.multi-select__value-container {
  overflow-y: auto !important;
}

.specify {
  display: inline-block;
  color: rgba(128, 128, 128, 0.479);
  margin-right: 3px;
  font-size: 13px;
  height:20px;
  font-weight: normal;
}
ul.iva {
  display: inline-block;
}
ul.iva li {
  font-size: 14px;
}
.height0 {
  height: 0px !important;
  border: none !important;
  transition: all 0.3s ease-in-out;
  transition-delay: all 0.4s;
  width: 50% !important;
}
.height45 {
  height: 50px !important;
  transition: all 0.3s ease-in-out;
  float: right;
  margin-right: auto;
  width: 65% !important;
}
.MuiAccordionDetails-root .row_div:nth-child(even) {
  background: rgba(0, 0, 255, 0.13);
}

.MuiAccordionDetails-root .cell_div {
  width: 40vw;
}
.MuiAccordionDetails-root .cell_div:last-child {
  text-align: right;
}
.MuiAccordionDetails-root .cell_div:first-child {
  width: 250px;
  max-height: 25px;
  padding: 0;
  margin: 0;
}
.MuiAccordionDetails-root .cell_div span {
  margin-top: -20px;
}
/* .MuiAccordionDetails-root .cell_div:nth-child(3) {
  text-align: left;
} */
.MuiAccordionDetails-root .cell_div:nth-child(3) {
  padding: 0;
  text-align: left;
  max-height: 10px;
  margin: 0;
}
.padding {
  padding: 10px;
}
.preview_product {
  border-radius: 5px;
  max-width: 60px;
  margin: 0;
  min-width:50px;
  padding: 0;
  line-height: 10px;
}
.prezzo_barrato {
  text-decoration: line-through;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.39);
}
.label span {
  vertical-align: middle;
}
.moreup {
  margin-top: -50px;
}
.col.name .preview_product {
  border-radius: 5px;
  max-height: 60px;
  max-width: 60px;
  margin-left: auto;
  margin: 0;
  padding: 0;
  line-height: 10px;
}
#chooser{
  
}

.icon_accordion i {
  margin: 0 5px;
  cursor: pointer;
}
#panel1a-header > div.MuiAccordionSummary-content.Mui-expanded > span,
#panel1a-header > div.MuiAccordionSummary-content > span {
  font-weight: 700;
  font-size: 14px;
}
#checkk {
  margin-left: 0px;
  color: rgb(25, 199, 25);
  transition: all 0.5s ease-in-out;
}
.fa.fa-check {
  margin-bottom: 15px;
}
.MuiIconButton-root {
  color: black !important;
}
.icon_accordion.arrows i {
  margin: 0 3px;
}
.icon_accordion.arrows {
  color: black;
  margin-left: 0px;
  transition: all 0.5s ease-in-out;
}
.icon_accordion {
  font-size: 15px;
  color: black;
  margin-left: auto;
  transition: all 0.5s ease-in-out;
}
.input_container i {
  position: absolute;
}

input:disabled {
  z-index: 5 !important;
}
.input_container {
  width: 100%;
}

.icon {
  padding: 0px;
  margin-top: 16px;
  margin-left: 7px;
}

.input-field {
  width: 100%;
  text-align: center;
}
.btn_outlined {
  background: transparent;
  border: 2px solid black;
  color: black;
  padding: 10px;
}
@media (max-width: 1400px) {
  .preventivatore .cell_div {
    padding: 10px;
  }
}
.prev_card.img_loader .subtitle {
  padding: 12.5px 25px;
}
.btn_outlined:hover,
.btn_outlined:visited,
.btn_outlined:focus,
.btn_outlined:active {
  border: 2px solid black;
  background: rgb(38, 118, 238) !important;
  color: white !important;
}
.btn_outlined:active {
  color: black;
}
.prev_card button {
  margin: 0 10px;
}

@media (min-width: 1200px) {
  .preventivatore .table_div.middleOne {
    margin-top: 50px;
  }
  .specify {
    display: block;
  }
}
@media (max-width: 1250px) and (min-width: 1000px) {
  .float {
    position: absolute;
    transition: all 0.5s ease-in-out;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    z-index: 9999;
    margin-top: 30px;
  }
  .product_wrapper .prev_card {
    margin: 15px 0;
  }
  .big_button {
    width: 120px;
    height: 120px;
    border-radius: 3px;
    max-width: none;
    max-height: none;
  }
  .product_wrapper .prev_card {
    margin-left: 150px;
  }
}
@media (max-width: 1000px) {
  #dati_spedizione_checkout .cell_div div.prev_card {
    height: 340px;
    display: block;
  }
  .MuiAccordionDetails-root .cell_div:first-child {
    width: 250px;
    max-height: 25px;
    padding: 0;
    margin: 0;
  }
  .MuiAccordionDetails-root .cell_div:nth-child(2) {
    width: 15%;
    max-width: 100px;
    padding: 0;
    text-align: left;
    overflow-x: hidden;
    max-height: 10px;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 15px;
  }
  .cell_div button {
    padding: 8px;
  }
  .adder {
    margin-left: initial;
  }
  .phaser {
    font-weight: 500;
    font-size: 12px;
  }
  .fadeIn.inline_block {
    transform: none;
  }
  .cell_div.address {
    width: 60%;
  }
  .adder .prev_card{
    margin:15px 0px;
  }
  .fadeIn .adder {
    transform: translate(-4px, -30px);
    z-index: 10000;
  }
  .big_button.fade {
    opacity: 0.1;
  }
  .big_button {
    width: 120px;
    height: 120px;
    border-radius: 3px;
    max-width: none;
    max-height: none;
    z-index: 1000;
  }
  .product_wrapper .prev_card {
    margin-left: 150px;
  }
}

@media (max-width: 850px) {
  #dati_spedizione_checkout .cell_div div.prev_card {
    width: 100%;
  }
  .big_button {
    margin: 8px 10px;
  }
  .table_div,
  .row_div {
    /* display: inline-block !important; */
    text-align: left;
    align-self: left;
    width: 100% !important;
  }
  #dati_spedizione_checkout .cell_div,
  .cell_div {
    padding: 12.5px 0;
  }

  .product_wrapper .prev_card {
    margin: 12.5px 0px;

    width: auto;
  }
  .adder{
    margin-top:150px;
    transition:all 0.3s ease-in-out;
  }
  .adder .prev_card.searchable {
    margin-bottom: 0px;
    width: auto;
  }
}
@media (max-width: 550px) {
  .height350 {
    height: auto;
  }
  .img_loader .table_div {
    display: table !important;
  }
  .img_loader .row_div {
    display: table-row !important;
  }
  .img_loader .cell_div {
    display: table-cell !important;
  }
  .preventivatore .cell_div {
    padding: 0px;
  }
  .middleOne .cell_div:nth-child(2) {
    padding: 0px 0px 0px 0px !important;
  }
  .table_div,
  .row_div,
  .cell_div {
    display: inline-block !important;
  }
  .page_title.absolute::after {
    content: "";
    height: 2px;
    margin: 2px 0;
    display: block;
    position: static;
    width: 20px;
    background: black;
  }
  .page_title {
    font-size: 16px;
  }
}
