.footer_employee {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: white;
  border-top: 2px solid red;
  vertical-align: middle;
}
.footer_employee img {
  display: inline-block;
  height: 50px;
}
