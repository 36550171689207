.sidebar_mobile ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.212);
  width: 100%;
  font-size: 20px;
  padding: 15px 15px 15px 15px;
}
.sidebar_mobile ul li:last-of-type {
  border-bottom: none;
}
.sidebar_mobile ul {
  margin-top: 65px;
  overflow-x: hidden;
}
