.bozze_container .bozza_btn:first-child {
  margin-left: 0px;
}
.bozze_container .bozza_btn {
  cursor: auto;
}
.draft_team_container{
  border:1px solid rgba(0, 0, 0, 0.342)
}
.draft_team_container h1{
  font-size:24px;
  font-weight:600;
  margin-left: 33px;
}

.MuiFormLabel-root,.MuiInput-input
{
  margin:10px 0px;
  font-size:16px !important;
  font-weight: 500 !important;
}
p.iconafree {
  text-align: right;
  /*top: -10px;*/
  height: 1px;
  transition: all 0.1s ease;
  cursor: pointer;
}
.iconafree:hover {
  color: red;
  transition: all 0.1s ease;
  transform: none;
}
.bozza_btn img {
  height: 130px;
  margin-top: 5px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0px;
  display: inline-block;
}
.bozza_btn {
  width: 150px;
  padding: 10px;
  height: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.bozza_btn img:hover {
  box-shadow: -1px 5px 25px -5px rgba(0, 0, 0, 0.342);
  transform: translate3d(0, -2px, 0);
  transition: all 0.3s ease-in-out;
  background: white;
}
.bozza_btn p {
  line-height: 16px;
  padding: 0px;
  margin: 0px;
}
