.popup{
    position:fixed;
    z-index:99999;
    width:auto;
    padding:25px;
    border:2px solid rgba(0, 0, 0, 0.151);
    overflow-y: auto ;
    left:50%;
    border-radius:5px;
    background:white;
    max-height:80vh;
    top:50%;
}