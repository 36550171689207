.graybck {
  background: rgba(0, 0, 0, 0.096);
  display: inline-block;
  text-align: left;
  padding: 20px;
}
.total {
  font-weight: 700;
}
.row_div.bottombordered {
  outline: 0.5px solid rgba(128, 128, 128, 0.397);
}
.graybck hr {
  width: 95%;
}
.graybck .cell_div {
  text-align: left;
}
.graybck .subtitle {
  text-transform: uppercase;
  font-size: 13px;
}
#dati_spedizione_checkout {
  padding: 0px;
  margin: 0px auto;
  height: auto;
}
#dati_spedizione_checkout > div:nth-child(1) > div:nth-child(1) {
  padding-left: 0px;
}
.checkout_Page .cell_div:nth-child(2) {
  text-align: right;
}
.semi_total {
  font-weight: 600;
  text-transform: uppercase;
}
.leftalign .cell_div,
.leftalign {
  text-align: left;
}
.rightalign td,
.rightalign .cell_div,
.rightalign {
  text-align: right;
  white-space: nowrap;
}
.table-order-items h1 {
  font-size: 30px;
  margin-bottom: 5px;
}

.table-order-items .col.name {
  width: 450px;
}
.table-order-items .col.img {
  width: auto;
}
.table-order-items .col.sku {
  width: 80px;
}
.select_modify__indicators{
  opacity:0;
  visibility:hidden;
  width:0px;
  height:0px;
}
 div.open.absolute_checkout.prev_card  button{
padding:11px;
width:auto;
font-size:15px;
display:inline-block;
margin:0px;

}
td.col.desc div,
td.col.name div{
  font-weight:normal;
  color:black;
  min-width:150px;
  max-height:200px;
  overflow-y:auto;
}
.table-order-items .col.price,
.col.iva,
.col.discount,
.col.qty {
  width: 15%;
  text-align: center;
}
.table-order-items span.section-title {
  font-size: 18px;
  display: inline-block;
  font-family: "ChronicleDisplay";
}
.table-order-items hr {
  margin-top: 5px;
  margin-bottom: 25px;
}
.useless {
}
.absolute_checkout {
  position: fixed;
  transform: translateX(240px);
  top: calc(50% - 200px);
  right: -10px;
  width: 350px;
  z-index: 99999;
  height: auto;
}
.absolute_checkout strong {
  font-size: 13px;
  text-transform: uppercase;
  white-space: nowrap;
}
.absolute_checkout td {
  font-size: 12px;
  padding-bottom:0px;
  padding-top:0px;
  font-weight: 700;
}
.absolute_checkout input {
  height: 30px !important;
  padding: 5px !important;
  text-align: right;
  width: 120px !important;
}
.absolute_checkout p {
  color: black;
}
.absolute_checkout.open,
.absolute_checkout:hover,
.absolute_checkout:active,
.absolute_checkout:focus {
  transform: translateX(0px);
  transition: all 0.3s ease-in-out;
}
.cliccable {
  cursor: pointer;
  text-decoration: underline;
}
.capitalize {
  text-transform: capitalize;
}
.col.img {
  text-align: center;
}
td.col,
th.col {
  vertical-align: middle;
  white-space: normal;
  border-bottom:1px solid rgba(0, 0, 0, 0.205);
  /*white-space: nowrap;*/
}
tr:last-child{
  border-bottom:none
}
.select_modify {
  height: 20px;
  vertical-align: middle;
  margin-bottom:25px;
}
.select_modify * {
  background: transparent !important;
  border: none !important;  
  box-shadow: none !important;
}
.select_modify__value-container,.select_modify__control{
  height:20px !important;
  width: 100%;
  min-width:150px;
}
.select_modify__placeholder{
  white-space: nowrap;
  left:0px;
  width:100%;
}
.select_modify__menu {
  background: white !important;
  border: 1px solid black !important;
  color: black !important;
  vertical-align: initial;
  height: auto;
}
._expanded {
  transition: all 0.3s ease-in-out;
  overflow: visible;
  margin-left: auto;
}
.fa {
  transition: all 0.3s ease-in-out;
}
.fa.fa-pencil,
.fa.fa-cross {
  margin-left: 5px;
  cursor: pointer;
}
.ordered input,
.det input {
  width: 60%;
  min-width: 70px;
  padding: 5px 10px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
}
.footerTable {
  width: 100%;
  text-align: right;
  display: table;
}
.footerTable .ro {
  display: table-row;
}
.footerTable .cel {
  display: table-cell;
  white-space: nowrap;
  padding-left: 5px;
}
.footerTable .ro .cel:first-child {
  width: 90%;
}
.footerTable p {
  color: black;
}
.height550 {
  height: 475px;
  overflow-y: visible;
  margin-top: 25px;
  transition: all 0.5s ease-in-out;
}
.height550 .prev_card {
  margin: 0px;
}
#dati_spedizione_checkout input {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid black !important;
  border-radius: 0px;
  padding: 0px;
  width: 40%;
  font-weight: 500;
  height: 16px;
  font-size: 15px;
  min-width: 60px;
}
#dati_spedizione_checkout .css-2b097c-container,.react-select__modify {
  display: inline-block;
}
.btn_crea.react-select {
  min-width: 250px;
  height: 52px;
  border:2px solid;
  padding: 0px;
  vertical-align: middle;
}
.react-select__menu {
  background: white !important;
  border: 1px black !important;
  border-radius: 5px !important;
  color: black !important;
}
.react-select__value-container,
.react-select__placeholder,
.react-select__control,
.react-select__single-value,
.react-select__input,
.react-select__indicators {
  background: transparent;
  background-color: transparent;
  border: none !important;
  color: white !important;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
  font-family: "Gotham-Medium";
  font-weight: 400;
  margin: 0;
  box-sizing: border-box;
  vertical-align: middle;
}

.react-select__control {
  background-color: transparent !important;
  border: none;
  appearance: button;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-image: initial;
}

#dati_spedizione_checkout {
  padding: 0px;
}
#dati_spedizione_checkout .cell_div {
  padding: 4px 12.5px;
  width: 30%;
}
#dati_spedizione_checkout .cell_div div.prev_card {
  height: 300px;
}
#dati_spedizione_checkout .cell_div:last-child {
  padding-right: 0px;
}
.select-modify {
  height: 40px;
}
@media (max-width: 1237px) {
  .btn_crea.react-select {
    display: block;
    min-width: 200px;
    max-width: 240px;
    height: 52px;
    padding: 0px;
    margin: 0px auto;
    margin-top: 10px;
    vertical-align: middle;
  }
}
