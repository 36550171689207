.chart_container {
  text-align: center;
}
.chart.mini img {
  height: 32px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.19);
}
.layout {
  margin-left: -16px;
}
.chart {
  -webkit-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.28);
  border-radius: 6px;
  margin: 25px;
}
.chart div {
  padding: 15%;
}
.chart.mini {
  text-align: center;
  height: 200px;
  width: 200px;
  max-height: 10vw;
  max-width: 10vw;
  margin: 25px;
  overflow: hidden;
  display: inline-block;
}
.chart.full {
  display: inline-block;
  max-width: 1200px;
  width: 100%;
  height: auto;
}
.chart.mini h3 {
  font-size: 15px;
  font-weight: 600;
  margin: 4px 0;
  color: rgba(0, 0, 0, 0.432);
  text-transform: capitalize;
}
.chart.half {
  min-width: 400px;
  text-align: center;
  width: 480px;
  display: inline-block;
  height: auto;
  padding: 3%;
}
.chart.mini h3 {
  white-space: nowrap;
}
.big_button._auto h2 {
  text-align: left;
}
.padding_class {
  padding: 0px 26px;
  margin-bottom: 10px;
  height: 80%;
  overflow-y: auto;
}
.big_button._auto {
  width: 100%;
  height: 100%;
  max-width: none;
  padding: 0;
  max-height: none;
  border-radius: 3px;
  overflow: hidden;
  display: block;
}
.big_button._auto table#my-orders-table-ord {
  table-layout: fixed;
}
.little_one {
  font-size: 0.4em;
  text-decoration: underline;
  display: inline-block;
  padding-left: 10px;
  margin-top: 0px !important;
}
.col.name:first-child {
  padding-left: 0px;
}
.card_auto th.col.id:first-child {
  padding-left: 0px;
}
.card_auto h2 {
  margin: 25px;
}
.layout {
  width: 100%;
  display: block;
  height: auto;
}
.card_auto canvas {
  max-height: 100%;
  resize: both;
}
._auto img {
  height: 25%;
  border-radius: 50%;
  margin-left: auto;
  margin-top: 15%;
  vertical-align: middle;
  margin-right: auto;
  display: inline-block;
  -webkit-box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.19);
}
.card_auto table {
  padding: 15px;
}
@media (max-width: 1200px) {
  .chart.mini {
    max-height: 10vw;
    min-height: 100px;
    min-width: 100px;
    max-width: 10vw;
    margin: 10px;
  }
  .chart div {
    padding: 2px;
  }
  .chart.mini h3 {
    font-size: 80%;
    margin: 2px;
  }
  .chart.mini img {
    height: 20px;
    margin: 0;
  }
  .chart.mini span[type="number"] {
    font-size: 15px !important;
    font-weight: 500;
  }
  .chart.full {
    display: inline-block;
    max-width: 850px;
    width: 90%;
    height: auto;
  }
}

.freccia{
  margin-top: 0px !important;


}
