.header {
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  background: white;
  left: 0;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.logomohd {
  background: #d90916;
  overflow: hidden;
  width: 155px;
  height: 60px;
}
.logomohd img {
  padding: 15px;
  width: 123px;
  transition: all 0.3s ease-in-out;
  margin-top: 0;
  overflow: hidden;
}
.header nav {
  display: inline-block;
  float: right;
  vertical-align: middle;
  height: 60px;
  width: auto;
}
.header nav ul {
  margin-top: 15px;
}
.header nav li {
  float: left;
  margin: 0 5px;
  font-size: 13px;
}
.header nav li i {
  float: left;
  margin: 0 5px;
  font-size: 25px;
}
.header nav ul li:last-child {
  margin-right: 21px;
}
#root > div > div > div > nav.hamburger {
  margin-top: 5px;
  float: left;
}
#root > div > div > div > nav.hamburger > ul > li {
  height: 2.2px;
  width: 25px;
  margin: 5px 10px;
  border-radius: 3px;
  background: #d90916;
  float: none;
  transition: all 0.3s ease-in-out;
}
.open li:nth-child(3) {
  transform: translateY(-9px) rotate(45deg);
  width: 10px;
}
.open li:nth-child(2) {
  opacity: 0;
}
.open li:nth-child(1) {
  transform: translateY(5px) rotate(-45deg);
  width: 10px;
}
@media (max-width: 1024px) {
  .logomohd {
    float: left;
  }
  #root > div > div > div > nav.hamburger {
    margin-top: 5px;
    float: none;
  }
  .logomohd img {
    padding: 16px;
    padding-left: 12px;
    width: 100px;
    margin-top: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
  }
  .logomohd {
    background: #d90916;
    overflow: hidden;
    width: 126px;
    height: 60px;
  }
}
