.invalid-feedback {
  color: rgba(255, 0, 0, 0.678);
}
.block-content {
  text-align: left;
}
.subtitle {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.315);
  font-weight: 500;
  text-align: left;
}
#social-login-popup.prev_card{
  margin:15px 0px;
}
.pulsa p{
  animation:pulsap;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: 4;
}
@keyframes pulsap{
  from{
    color:gray
  }
  to{
    color:red;
  }
}
#maincontent
  > div
  > div.column.main
  > div
  > div
  > div:nth-child(2)
  > form
  label {
  color: rgba(0, 0, 0, 0.315);
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 15px;
}

label.check_ {
  text-transform: initial;
}
.newcust_form {
  padding: 15px;
}
.newcust_form input:not([type="checkbox"]) {
  width: 90%;
}
.newcust_form input[name="email"] {
  width: 95%;
}
.invalid-feedback{
  position: absolute;
}
.newcust_form button {
  margin-top: 10px;
  border-radius: 3px;
}
.newcust_form label a {
  color: #2b98f0;
  text-decoration: underline;
}
.fixedLength {
  width: 50%;
  min-width: 150px;
}
.newcust_form input[type="checkbox"].checkbox {
  border: none;
  -webkit-border-radius: 3px;
  background: transparent;
  border: 0;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 23px;
  height: 23px;
  margin: 0 10px 0 0;
  -moz-box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);
}
#privacyCheck.jcf-checked:focus {
  border-color: #000 !important;
}

.newClient_container .table_div .cell_div {
  padding: 20px;
}

.uppercase {
  text-transform: uppercase;
}
.longone {
  width: 80%;
}
.widthVis{
  animation-name: grow ;
  animation-direction: normal ;
  animation-fill-mode: forwards ;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  max-width:50%;
}
.width0, .width0 *{
  max-width:0px;
  opacity:0;
  visibility: hidden;
  overflow:hidden
}

@keyframes grow{
  0%{
    opacity:0;
    max-width:0px;
  }
 
  100%{
    opacity:1;
    max-width:400px;
  }
}
.anagrafy-select__menu{
  max-height: 200px;
}