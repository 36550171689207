.pagination_container {
  font-weight: 300;
  width: 100%;
}

.page_left {
  float: left;
  padding: 10px;
  color: black;
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
}
.col.actions.rightalign button{
  padding:10px 3px;
}
.page_right {
  float: right;
  padding: 10px;
  font-weight: 500;
  font-size: 15px;
  outline: none;
  border: none;
  background: none;
  color: black;
}
.page_right::selection,
.page_right span::selection {
  background: transparent;
}
.page_right span {
  font-size: 23px;
  cursor: pointer;
  padding: 2px;
}
.pagination_container span:hover {
  color: red;
}

.prev_card.searchable.orders{
  padding:50px;
}
#my-orders-table-ord td.col:first-child {
  width: auto;
}
.col.status {
  text-align: center;
}
.col.actions.rightalign a  .btn_outlined,.col.actions.rightalign  .btn_outlined{
  width:auto;
  height:50px;
  padding:15px 10px;
}
#my-orders-table-ord{
  table-layout: fixed !important;
}
@media (max-width:1000px)
{
  .col.actions.rightalign a  .btn_outlined,.col.actions.rightalign  .btn_outlined{
    width:auto;
    height:30px;
    font-size:11px;
    font-weight:500;
    padding:2px;
  }
}