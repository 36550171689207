@charset "UTF-8";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css";
/* @font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/font/poppins-v6-latin-regular.woff) format('woff');
}*/
:root {
  --marginhead: 130px;
  --marginside: 20vw;
}

.columns {
  margin-right: auto;
}

@media (max-width: 1200px) {
  td.col,
  th.col {
    white-space: normal !important;
    font-size: 12px;
  }
  td.col.status,
  td.col.total {
    font-size: 11px;
  }
}
/* 
.table {
  table-layout: fixed;
} */
/* *{
  font-family:zopim, "Segoe UI Symbol", "Arial Unicode MS", "Lucida Sans Unicode", sans-serif;
}
*.enter-done{
  transition: transform 0.5s ease-in-out;
  transition-delay:200ms;
  z-index:300;
  transform:translateX(0vw);
  opacity:1;
  }
*.enter.enter-active{
  transform:translateX(100vw);
  transition-delay:opacity 0s, transform 0.3s;
  opacity:0;
  z-index:300;
  transition: opacity 0s, transform 0.4s ease-in-out;
}
*.exit.exit-active{
  transform:translateX(-100vw);
  z-index:300;
  opacity:0;
  transition:transform 0.2s, opacity 0.2s ease-in-out;
}
*.exit{
  opacity:0;
} */

.mother {
  height: 100%;
  width: 100vw;
  display: flex;
  background: none;
  flex: 1;
  overflow-x: hidden;
}
.swal_container button{
  padding:10px;
  margin:2px 5px;
  width:40%;
  outline:none;
  border:2px solid;
  
}
.swal_container > div {
  position: absolute;
  top: 100px;
  right: 25px;
}
.swal_container {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}
body > div.swal2-container.swal2-center.swal_container.swal2-backdrop-show > div > div.swal2-actions > button.swal2-confirm.btn_modifica.swal2-styled{
  padding:10px;
  margin:2px 5px;
  width:40%;
  border-radius:0px;
  outline:2px solid transparent;
  border:none;
}
body > div.swal2-container.swal2-center.swal_container.swal2-backdrop-show > div > div.swal2-actions > button.swal2-confirm.btn_modifica.swal2-styled:hover{
  outline:2px solid #05a117;
}
.swal_container p,#swal2-content {
  text-align: center;
  font-size: 16px;
}
.swal_container h1,h2 {
  font-size: 28px !important;
  line-height: 28px;
  margin-bottom: 15px;
  color: #000;
  font-family: "ChronicleDisplay";
}
.swal_container h1::after {
  content: "";
  opacity: 0;
  position: absolute;
}
.body {
  margin-top: var(--marginhead);
  margin-left: var(--marginside);
  z-index: 300;
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: white;
}
.loader td,
.loader li {
  height: 21px;
  padding: 6px;
}
.loader td > span,
.loader li > span {
  background: linear-gradient(45deg, #cacaca65, #b4b3b341, #cacaca, #b1b1b156);
  background-size: 600% 100%;
  animation: gradient2 2s linear infinite;
  animation-direction: alternate-reverse;
  height: 100%;
  opacity: 0;
  animation-fill-mode: forwards;
  transition: all 0.3s ease-in-out;
  width: 100%;
  border-radius: 2px;
  display: block;
  animation-direction: alternate;
}
#root {
  overflow-x: hidden !important;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  background: #d90916;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #990000;
}
::-webkit-scrollbar-thumb:active {
  background: #750000;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none transparent;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
header.loading,
footer.loading {
  background: linear-gradient(
    45deg,
    #cacaca65,
    #b4b3b341,
    #d90916,
    #aaaaaa34,
    #b1b1b156
  );
  background-size: 600% 100%;
  animation: gradient 3s linear infinite;
  animation-direction: alternate-reverse;
}
.page-main {
  background: white;
}
.column.main {
  min-height: 70vh;
}
.page_containerloader {
  height: 100vh;
  width: 100vw;
  padding-top: 0;
  overflow: visible;
}

@keyframes gradient2 {
  0% {
    opacity: 1;
    background-position: 0%;
  }
  100% {
    opacity: 1;
    background-position: 100%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}
@keyframes pulse {
  from {
    opacity: 0.4;
    background: rgba(0, 0, 0, 0.192);
  }

  to {
    opacity: 1;
    background: rgba(0, 0, 0, 0.144);
  }
}
.page-main {
  margin-top: 60px !important;
}
.page_container {
  padding: 180px;
  text-align: center;
  background: #f1f3f4;
  color: rgb(74, 89, 96);
}
body {
  background: #f1f3f4;
  color: rgb(74, 89, 96);
}
.page_container.home {
  padding: 140px 9px;
  text-align: center;
}
.page_container.home .page_title {
  padding-left: 100px;
}
.center_align {
  text-align: center !important;
}
.left_align {
  text-align: left !important;
}
.page_container table {
  text-align: left;
}
.page_title {
  width: 100%;
  text-align: left;
}
.page_title::after {
  content: "";
  height: 7px;
  margin: 15px 0;
  display: block;
  width: 70px;
  background: black;
}
@media (max-width: 1300px) {
  .page_container {
    padding: 180px 140px;
    text-align: center;
  }
  .page_container.home {
    padding: 140px 140px;
    text-align: center;
  }
  .page_container.home .page_title {
    padding-left: 0px;
  }
}

@media (max-width: 1100px) {
  .page_container {
    padding: 50px;
    padding-top: 150px;
  }
  html
    body.account.sales-order-history.page-layout-2columns-left
    div.page-wrapper
    div#root
    div
    div.animated.fadeIn
    div.page_container.left_align
    div.newClient_container
    div#social-login-popup.prev_card {
    padding: 0px;
  }
  .page_container.home {
    padding: 50px;
    text-align: center;
  }
  .page_container.home .page_title {
    padding-left: 0px;
  }
}

@media (max-width: 1000px) {
  .page_container {
    padding: 50px 15px;
    overflow: auto;
  }
  .page_container.home {
    padding: 50px 15px;
    text-align: center;
    overflow: auto;
  }
}

@media (max-width: 767px) {
  .page-main {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
}
