.current strong {
  color: #d90916;
  transition: color 0.3s ease-in-out;
}
.nav-item strong {
  font-weight: 500;
  font-size: 15px;
}
.nav-item {
  padding: 8px 0;
  line-height: 20px;
}
.nav-items hr {
  margin: 2px;
}
.inner_ul {
  padding-left: 8px;
}
.inner_ul > li {
  padding-left: 8px;
}
.inner_ul > li:first-child {
  color: #d90916;
  text-transform: uppercase;
  padding-left: 0px;
  font-weight: 500 !important;
}
