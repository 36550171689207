.label_stampa *{
    vertical-align: middle;
}
.label_stampa input{
    margin:0px 0px 0px 5px;
    padding:0px;
}
.label_stampa label{
    font-size:13px;
    font-style: oblique;
}
.checkbox_container{
    white-space: nowrap;
    text-align:justify;
    margin:0px auto;
}
