.filter_input{
    width:30% !important;
    min-width:180px;
    margin:6px;
    display:inline-block;
    font-weight:500;
    font-size:14px;
}
/* .ease:nth-child(odd){
    width:30% !important;
    margin:0px;
    margin-right:6px;
    margin-left:0px !important;
} */
.classeconsfondonero{
    background:black;
}
.classeconsfondobianco
{
    background:white;
}
.ease:nth-child(odd){
    margin:0px;
    margin-right:6px;
    margin-left:0px !important;
}
.filter_action{
    cursor: pointer;
}
.ease{
    transition:all 0.3s ease-in-out;
    padding-top:15px;
}
.prev_card.searchable .height0{
    width:100% !important;
}
.float_right{
    float:right;
    cursor: pointer;
}

.height0 input{
    opacity:1;
    width:30% !important;
    min-width:100px;
    animation-name: fadeOut;
    animation-fill-mode: forwards;
    animation-duration:0.3s;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
}
.ease input{
    opacity:0;
    animation-name: fadeIn;
    animation-delay:0.5s;
    animation-fill-mode: forwards;
    animation-duration:0.3s;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
}