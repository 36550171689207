.folder {
  background-image: url("../../assets/folder.png");
  width: 220px;
  height: 220px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.folder span {
  position: absolute;
  bottom: 10px;
  font-weight: 700;
  left: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.file {
  background-image: url("../../assets/file.png");
  width: 220px;
  height: 220px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.file span {
  position: absolute;
  bottom: -14px !important;
  width: 120px;
  word-wrap: break-word;
  font-size: 12px;
  line-height: 12px;
  text-overflow: clip;
  text-align: start;
  height: 50px;
  vertical-align: top;
  overflow: hidden;
  font-weight: 500;
  left: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.folder img,
.file img {
  position: absolute;
  top: 40%;
  max-width: 150px;
  max-height: 90px;
  left: 50px;
  transition: all 0.3s ease-in-out;
}
.loadingDiv {
  animation: pulse 1s infinite;
  animation-direction: alternate;
  width: 250px;
  height: 250px;
  margin: 15px;
  border-radius: 3px;
}

.loadingDiv {
  animation: pulse 1s infinite;
  width: 150px;
  height: 150px;
}
.folder {
  background-image: url("../../assets/folder.png");
  width: 120px;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
}
.file {
  background-image: url("../../assets/file.png");
  width: 120px;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
}
.folder span {
  position: absolute;
  bottom: 0px;
  font-weight: 700;
  width: 120px;
  left: 8px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file span {
  bottom: -15px !important;
  left: 5px;
  font-size: 11px;
  width: 110px;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.folder img,
.file img {
  position: absolute;
  top: 40%;
  width: 70px;
  left: 40px;
}

/*  */
/*@media (max-width: 1100px) {*/
/*  .loadingDiv {*/
/*    animation: pulse 1s infinite;*/
/*    width: 150px;*/
/*    height: 150px;*/
/*  }*/
/*  .folder {*/
/*    background-image: url("../../assets/folder.png");*/
/*    width: 120px;*/
/*    height: 120px;*/
/*    background-size: contain;*/
/*    background-repeat: no-repeat;*/
/*    display: inline-block;*/
/*    cursor: pointer;*/
/*  }*/
/*  .file {*/
/*    background-image: url("../../assets/file.png");*/
/*    width: 120px;*/
/*    height: 120px;*/
/*    background-size: contain;*/
/*    background-repeat: no-repeat;*/
/*    display: inline-block;*/
/*    cursor: pointer;*/
/*  }*/
/*  .folder span {*/
/*    position: absolute;*/
/*    bottom: 0px;*/
/*    font-weight: 700;*/
/*    width: 120px;*/
/*    left: 8px;*/
/*    cursor: pointer;*/
/*    text-overflow: ellipsis;*/
/*    white-space: nowrap;*/
/*    overflow: hidden;*/
/*    text-overflow: ellipsis;*/
/*  }*/
/*  .file span {*/
/*    bottom: -15px !important;*/
/*    left: 5px;*/
/*    font-size: 11px;*/
/*    width: 110px;*/
/*    height: 25px;*/
/*    white-space: nowrap;*/
/*    overflow: hidden;*/
/*    text-overflow: ellipsis;*/
/*  }*/
/*  .folder img,*/
/*  .file img {*/
/*    position: absolute;*/
/*    top: 40%;*/
/*    width: 70px;*/
/*    left: 40px;*/
/*  }*/
/*}*/
