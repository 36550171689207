.jodit_fullsize{
    top: 50px;
    margin-top: 45px;
    left: 0;
    right: 0;
    bottom: 0;
    max-height:60vh;
    max-width:90vw;
    z-index: 100000 !important;
    max-width: none!important;
}

.inline-input{
    display: inline-flex;

}
.attachment_div{
    border:1px solid gray;
    display: inline-block;
}
.attachment_div > div >p{
    font-size:12px;
    font-weight:700
}
.flex-container{
    display:flex;
    flex-direction: row;
    margin:10px 0px;
    align-items: center; 
    justify-content: center; 
    vertical-align: middle;
}
.flex-container .react-select,.flex-container .react-select > div{
display: flex;
flex-direction: row;
width:100%;
}
.flex-container label{
    font-weight: 600;
    font-size:13px;
   margin-right:5px;
    white-space: nowrap;
    text-transform: uppercase;
}
.html_editor_container{
    text-align:left;
    width:60vw;
    max-height:60vh;
    overflow:auto;
}
.html_editor_container.full{
    text-align:left;
    width:100%;
    max-height:none;
    overflow:auto;
}