.preventivatore .cell_div {
  padding: 15px;
}
.preventivatore .prev_card {
  margin: 25px 0;
}
.preventivatore .table_div {
  margin-top: 0px;
}
.phaser {
  font-weight: 500;
}
.phaser li {
  color: rgba(0, 0, 0, 0.322);
  cursor: pointer;
}
.phaser li.current {
  color: #d90916;
}
.fadeOutRight {
  width: 0px;
  height: 0px;
  transition-delay: all 0.5s;
  overflow: hidden;
}
.height0 {
  width: initial !important;
  height: 0px;
  transition: height 0.5s ease-in-out;
  overflow: hidden;
  display: inline-block;
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
}
.link{
  color:#d90916;
  font-size:12px;
}
.link:hover{
  text-decoration: underline;
  color:#940710
}
.innerswal {
  line-height: 20px;
  font-size: 15px;
  font-weight: 700;
}
.height250 {
  transition: height 0.5s ease-in-out;
  height: 250px;
  overflow: auto;
}
.height450 {
  transition: height 0.5s ease-in-out;
  height: 380px;
  overflow: auto;
}
.nobg {
  background: none !important;
  transform: none;
}
.fa-refresh{
  font-size:20px;
  margin-bottom:9.5px;
}
.preventivatore .field.choice.newsletter {
  white-space: nowrap;
  margin-top:5%;
  text-align: right;
}
@media (max-width: 900px) {
  .preventivatore .field.choice.newsletter {
    float: right;
    white-space: normal;
  }
}
.nobg:hover {
  transform: none !important;
}
div.swal-modal div.swal-content h1 {
  text-align: center;
}
div.swal-modal div.swal-content h1:after {
  opacity: 0;
}
.swal_container div ul {
  text-align: left;
  margin-left: 40px;
  padding: initial;
  list-style: initial;
}

.swal_container div ul li {
  padding: initial;
  list-style: initial;
  color: red;
}
button.pulsa {
  animation-name: pulsa;
  animation-duration: 2s;
  animation-direction: reverse;
  animation-play-state: running;
  outline: 1px solid white;
  animation-iteration-count: 5;
}
.subtitle.pulsa {
  animation-name: evidenzia;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-play-state: running;
  animation-iteration-count: 4;
}
@keyframes pulsa {
  0% {
    box-shadow: 0 0 0px 0 rgba(11, 211, 37, 0.4);
  }

  70% {
    box-shadow: 0 0 5px 10px rgba(11, 211, 37, 0.7);
  }

  100% {
    box-shadow: 0 0 0px 0 rgba(11, 211, 37, 0.4);
  }
}
@keyframes evidenzia {
  0% {
    color: rgba(11, 211, 37, 0.4);
  }

  70% {
    color: rgba(11, 211, 37, 0.7);
  }

  100% {
    color: rgba(11, 211, 37, 0.4);
    text-decoration: underline;
  }
}
