.accordion_component .icon_accordion{
    margin-left:10px;
  
}
.MuiAccordionDetails-root td:first-child{
  font-weight:600;
}
.MuiAccordionDetails-root td{
  font-weight: normal;
}
.MuiAccordionDetails-root thead{
  border-bottom:1px solid gray
}